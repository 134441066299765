import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Nav from './components/pages/Nav';
import Login from './components/pages/Login';
import CreateAccount from './components/pages/CreateAccount';
//import LandingPage from './components/pages/Landing';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserProfile from './components/pages/UserProfile';
import TopicSelector from './components/pages/TopicSelector';
import mixpanel from "mixpanel-browser";
import Feedback from './components/pages/Feedback';
import MyFeedback from './components/pages/MyFeedback';
import ShowFeedback from './components/pages/ShowFeedback';


function App() {

    // Near entry of your product, init Mixpanel
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_ID, {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
    });

    // Define user state
    const [user, setUser] = useState(() => {
        const storedUserInfo = localStorage.getItem("userInfo");
        return storedUserInfo ? JSON.parse(storedUserInfo) : null;
    });

    // Check if the user is logged in
    const isLoggedIn = () => {
        return !!localStorage.getItem("userInfo");
    };

    // PrivateRoute component to protect routes
    const PrivateRoute = ({ children }) => {
        return isLoggedIn() ? children : <Navigate to="/login" />;
    };

    const GoogleLogInAuthWrapper = () => {
        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Login setUser={setUser} />
            </GoogleOAuthProvider>
        );
    };

    const GoogleSignUpAuthWrapper = () => {
        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <CreateAccount />
            </GoogleOAuthProvider>
        );
    };

    return (
        <Router>
            <Nav user={user} />
            <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<GoogleLogInAuthWrapper />} />
                <Route path="/create-account" element={<GoogleSignUpAuthWrapper />} />
                
                {/* Protected Routes */}
                <Route
                    exact
                    path="/"
                    element={
                        <PrivateRoute>
                            <MyFeedback />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/user-profile"
                    element={
                        <PrivateRoute>
                            <UserProfile user={user} setUser={setUser} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/choose-topic"
                    element={
                        <PrivateRoute>
                            <TopicSelector/>
                        </PrivateRoute>
                    }
                />
                <Route 
                   path="/feedback" 
                   element={
                        <PrivateRoute>
                            <Feedback />
                        </PrivateRoute>
                        } 
                />
                <Route 
                   path="/my-feedback" 
                   element={
                        <PrivateRoute>
                            <MyFeedback />
                        </PrivateRoute>
                        } 
                />
                <Route 
                   path="/feedback/:id" 
                   element={
                        <PrivateRoute>
                            <ShowFeedback />
                        </PrivateRoute>
                        } 
                />
            </Routes>
        </Router>
    );
}

export default App;
