import React from "react";
import LoadingGIF from "../images/Loading.gif";

function Loading({ loadingText }) {
  if (!loadingText) {
    loadingText = "generating questions . . .";
  }

  const loadingCard = {
    width: "450px",
    height: "250px",
    padding: "20px",
    borderRadius: "15px",
    position: "absolute",
    backgroundColor: "#dbd7ce",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  };

  const loadingGIF = {
    width: "auto",
    height: "70%",
  };

  const loadingTextStyle = {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 600,
    textAlign: "center",
    display: "flex",
    gap: "2px", // Small spacing between letters
  };

  return (
    <div style={loadingCard}>
      <img style={loadingGIF} src={LoadingGIF} alt="loading..." />
      <div style={loadingTextStyle}>
        {loadingText.split("").map((char, index) => (
          <span
            key={index}
            style={{
              display: "inline-block",
              animation: "loading01 1.4s infinite alternate",
              animationDelay: `${index * 0.1}s`, // Dynamic stagger
              opacity: 1,
            }}
          >
            {char === " " ? "\u00A0" : char} {/* Preserve spaces */}
          </span>
        ))}
      </div>
      {/* Inline keyframes */}
      <style>
        {`
          @keyframes loading01 {
            0% { opacity: 1; }
            100% { opacity: 0.3; }
          }
        `}
      </style>
    </div>
  );
}

export default Loading;
