import React from 'react';
import { Link } from 'react-router-dom';

function Nav({ user }) {
    return (
        <div style={styles.nav}>
            <div style={styles.logo}>
                <Link to="/" style={styles.logoLink}>
                    <span style={styles.logoText}>ShikshaSathi</span>
                </Link>
            </div>

            <div style={styles.menuOptions}>
                {user ? (
                    <>
                        <NavButton to="/my-feedback" label="My Notes" />
                        <NavButton to="/choose-topic" label="Create New Notes" />
                        <UserProfile user={user} />
                    </>
                ) : (
                    <>
                        <NavButton to="/login" label="Login" />
                        {/* <NavButton to="/register" label="Sign Up" style={styles.createAccountButton} /> */}
                    </>
                )}
            </div>
        </div>
    );
}

function NavButton({ to, label, style }) {
    return (
        <Link to={to}>
            <button style={{ ...styles.baseButton, ...style }}>{label}</button>
        </Link>
    );
}

function UserProfile({ user }) {
    return (
        <Link to="/user-profile">
            <div style={styles.userProfile}>
                <img
                    src={user.picture || user.pic}
                    alt="User Profile"
                    style={styles.profileImage}
                />
            </div>
        </Link>
    );
}

export default Nav;


// NavStyles.js
const styles = {
    nav: {
        height: "56px",
        width: "100%",
        backgroundColor: "#1A1A1A",
        top: 0,
        position: "fixed",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
        userSelect: "none",
        zIndex: 10,
    },
    logo: { marginLeft: "4%" },
    logoLink: { textDecoration: "none" },
    logoText: {
        fontFamily: "'Krona One', sans-serif",
        fontWeight: 400,
        fontSize: "27px",
        color: "#FFFFFF",
    },
    menuOptions: {
        width: "auto",
        marginRight: "4%",
        display: "flex",
        alignItems: "center",
        gap: "1rem",
    },
    baseButton: {
        height: "30px",
        borderRadius: "10px",
        fontFamily: "'Lato', serif",
        fontWeight: 400,
        backgroundColor: "#FFFFFF",
        border: "none",
        cursor: "pointer",
        color: "black",
        padding: "0 15px",
    },
    createAccountButton: {
        color: "#FFFFFF",
        backgroundColor: "#1A1A1A",
        border: "1px solid #FFFFFF",
    },
    profileImage: {
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        cursor: "pointer",
    },
    userProfile: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
};


