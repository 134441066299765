import React, { useState, useEffect } from "react";
import QuestionAnswerScreen from "./QuestionAnswerScreen";
import Loading from "../loading/Loading";

const TopicSelector = () => {
  const [schema, setSchema] = useState(null);
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [topic, setTopic] = useState("");
  const [questions, setQuestions] = useState([]);
  const [showQAScreen, setShowQAScreen] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const loadingText = "Generating Questions for You . . ."

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BE_ENDPOINT}/api/schema`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error("Failed to fetch schema");
        }
        
        setSchema(data.ShikshaSathi); // Set the schema in state
      } catch (error) {
        console.error("Error fetching schema:", error.message);
      }
    };
    fetchSchema();
  }, []);

  const handleSubmit = async () => {
    if (!grade || !subject || !topic) {
      alert("Please select all fields before proceeding.");
      return;
    }

     // Remove the `.pdf` extension from the topic
     const cleanTopic = topic.replace(/\.pdf$/, ""); // Strip .pdf from the topic

    const jsonOutput = {
      grade: `${grade}/${subject}`,
      topic: cleanTopic,
    };

    console.log("JSON Output:", JSON.stringify(jsonOutput, null, 2));

    try {
      setIsStarting(true);
      const response = await fetch(`${process.env.REACT_APP_BE_ENDPOINT}/api/fetch-and-parse-pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonOutput),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error fetching questions: ${errorText}`);
      }

      const data = await response.json();
      console.log("Fetched Data:", data);

      setQuestions(data.questions);

      console.log("passing heading",grade, subject, topic);

      setShowQAScreen({
        show: true,
        grade: grade,
        subject: subject,
        topic: cleanTopic,
      });
      
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setIsStarting(false);
    }
  };

  // Inject animations into a <style> tag
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes wave {
        from {
          transform: translateX(-50%) skew(0, -10deg);
        }
        to {
          transform: translateX(-30%) skew(10deg, 0);
        }
      }
    `;
    document.head.appendChild(style);
  }, []);

  const pageStyle = {
    fontFamily: 'Inter',
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#f8f8f5",
    // backgroundColor: "#222222",
  };

  // const waveStyle = {
  //   content: "''",
  //   position: "fixed",
  //   width: "300%",
  //   height: "100%",
  //   top: "45%",
  //   left: "50%",
  //   backgroundColor: "#282828",
  //   borderRadius: "50%",
  //   transform: "translateX(-50%) skew(0, -10deg)",
  //   animation: "wave 7s ease-in-out infinite alternate",
  // };

  const parentStyle = {
    width: "500px",
    padding: "20px",
    borderRadius: "10px",
    position: "absolute",
    top: "calc(50% - 300px)",
    left: "calc(50% - 250px)",
    
    textAlign: "center",
    textTransform: "capitalize",
  };

  const loadingContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
    width: '100vw', // Full viewport width
    position: 'fixed', // Ensure it stays in place
    top: 0,
    left: 0,
    zIndex: 1000, // Ensure it is on top
    
  };

  const cardStyle = {

    backgroundColor: "#dbd7ce",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "60px",
    flexDirection: "column",
    boxShadow: "outset 0.5px 0.5px 4px 1px #ccc",

    width: "450px",
    height: "250px",
    padding: "20px",
    borderRadius: "15px",
    position: "absolute",
   
  };

  const selectStyle = {
    width: "70%",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: isStarting ? "#cccccc" : "#1a1a1a",
    cursor: isStarting ? "not-allowed" : "pointer",
    textDecoration: "none",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    fontSize: "16px",
    marginTop: "10px",
    transition: "background-color 0.3s ease",
  };

  if (showQAScreen) {
    return (
      <QuestionAnswerScreen 
        questions={questions}
        grade={showQAScreen.grade}
        subject={showQAScreen.subject}
        topic={showQAScreen.topic}
      />
    );
    
  }

  const grades = schema ? Object.keys(schema) : [];
  const subjects = grade && schema[grade] ? Object.keys(schema[grade]) : [];

  const topics =
  grade && subject && schema[grade][subject]
    ? schema[grade][subject].files.sort((a, b) => {
        const chapterA = parseInt(a.match(/Chapter (\d+)/)?.[1] || 0, 10);
        const chapterB = parseInt(b.match(/Chapter (\d+)/)?.[1] || 0, 10);
        return chapterA - chapterB;
      }) || []
    : [];

  return (
    <div style={pageStyle}>
      {/* Wave background */}
      {/* <div style={waveStyle}></div> */}

      {isStarting ? 
        <div style={loadingContainer}>
          <Loading loadingText={loadingText} />
        </div>
        :
        <div style={parentStyle}>
              <h2 style={{ marginBottom: "50px", color: "#0F0E0E", fontSize: "30px", fontWeight: '700' }}>Get Started on Your Learning Journey!</h2>
              
              <div style={cardStyle}>
              <select style={selectStyle} value={grade} onChange={(e) => setGrade(e.target.value)}>
                <option value="">Grade</option>
                {grades.map((g) => (
                <option key={g} value={g}>
                  {g}
                </option>
              ))}
              </select>
      
              <select
                style={selectStyle}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                disabled={!grade}
              >
                <option value="">Subject</option>
                {subjects.map((s) => (
                <option key={s} value={s}>
                  {s}
                </option>
              ))}
              </select>
      
              <select
                style={selectStyle}
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                disabled={!subject}
              >
                <option value="">Topic</option>
                {topics.map((t) => (
                <option key={t} value={t}>
                  {t.replace(".pdf", "")}
                </option>
              ))}
              </select>
      
              <button style={buttonStyle} onClick={handleSubmit} disabled={!topic && isStarting}>
              {/* {isStarting ? "Starting..." : "Start Revision"} */}
              Start Revision
              </button>
      
              </div>
      
            </div>
      }


    </div>
  );
};

export default TopicSelector;
