import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Chat from './ChatPage';

const QuestionAnswerScreen = ({ questions, grade, subject, topic }) => {
  const navigate = useNavigate();
  const [updatedQuestions, setUpdatedQuestions] = useState(questions);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo ? userInfo._id : null;
  //console.log("User ID:", userId);
  // const [pdfUrl, setPdfUrl] = useState(""); // To store the PDF URL
  // const [showModal, setShowModal] = useState(false); // To control the modal visibility

  const handleAnswerUpdate = (index, answer) => {
    const updated = [...updatedQuestions];
    updated[index] = { ...updated[index], answer }; // Update the answer
    setUpdatedQuestions(updated);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Step 1: Call /api/evaluate
      const evaluateResponse = await fetch(`${process.env.REACT_APP_BE_ENDPOINT}/api/evaluate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grade: grade,
          topic: topic,
          questions: updatedQuestions.map((q) => ({
            question: q.question,
            answer: q.answer || "Not Answered",
          })),
        }),
      });

      if (!evaluateResponse.ok) {
        throw new Error("Error evaluating answers.");
      }

      const { answers : evaluations } = await evaluateResponse.json();
      //console.log("evaluations : "+ JSON.stringify(evaluations, null, 2));
      

      // Step 2: Call /api/personalized-feedback
      const feedbackResponse = await fetch(
        `${process.env.REACT_APP_BE_ENDPOINT}/api/personalized-feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            grade: grade,
            topic: topic,
            answers: evaluations }),
        }
      );

      if (!feedbackResponse.ok) {
        throw new Error("Error generating personalized feedback.");
      }

      // Parse the JSON response properly
      const feedbackData1 = await feedbackResponse.json();
      
      //console.log("Feedback Data to Navigate: ", feedbackData1);

      // Step 3: Save feedback to backend
      const saveFeedbackResponse = await fetch(`${process.env.REACT_APP_BE_ENDPOINT}/api/save-feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId, // Replace with the actual user ID from context or props
          feedbackContent: feedbackData1.feedbackContent, // Pass the feedback data here
          grade,
          subject,
          topic,
        }),
      });

      if (!saveFeedbackResponse.ok) {
        throw new Error("Error saving user feedback.");
      }

      const savedFeedback = await saveFeedbackResponse.json();
      console.log("Saved Feedback Response: ", savedFeedback);
     
      // Step 4: Navigate to feedback page

      navigate('/feedback', { state: { feedbackData1 }, grade, subject, topic });
     

    } catch (error) {
      console.error("Error during submission workflow:", error);
      alert("An error occurred. Please check the console for details.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // QnA section
  const pageStyle = {
   // backgroundColor: "#E8EAF5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: 'Inter',
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#f8f8f5",
  };
  // const waveStyle = {
  //   content: "''",
  //   position: "fixed",
  //   width: "300%",
  //   height: "100%",
  //   top: "45%",
  //   left: "50%",
  //   backgroundColor: "#282828",
  //   borderRadius: "50%",
  //   transform: "translateX(-50%) skew(0, -10deg)",
  //   animation: "wave 7s ease-in-out infinite alternate",
  // };

  return (
    <div
      style={pageStyle}
    >
      <div
        style={{
          width: "60%",
          padding: "30px",
          // backgroundColor: "#ffffff",
          // borderRadius: "10px",
          // boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          zIndex: 1
        }}
      >
        <Chat
          questions={updatedQuestions}
          onAnswerUpdate={handleAnswerUpdate}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          grade={grade}
          subject={subject}
          topic={topic}
        />
      </div>
      {/* <div style={waveStyle}></div> */}
    </div>
  );
};

export default QuestionAnswerScreen;
