import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserProfile = ({ user, setUser }) => {
  // const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false); // State to track hover
  const [formData, setFormData] = useState({
    grade: '',
    location: '',
    preferredLanguage: '',
    board: '',
    hobbies: '',
    preparingFor: ''
  });

  const statesOfIndia = [
    "Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar",
    "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli and Daman and Diu", "Delhi", "Goa",
    "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala",
    "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
    "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan",
    "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];

  const grades = [
    "1st Grade", "2nd Grade", "3rd Grade", "4th Grade", "5th Grade",
    "6th Grade", "7th Grade", "8th Grade", "9th Grade", "10th Grade",
    "11th Grade", "12th Grade", "Undergraduate", "Postgraduate"
  ];

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Data: ", formData);
    localStorage.setItem("userInfo", JSON.stringify(formData));
    setUser(formData);
    alert("Profile updated successfully!");
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    setUser(null);
    navigate('/');
};

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <form onSubmit={handleSubmit}>
          <div style={styles.header}>
            <img style={styles.image} src={user?.pic || ""} alt="Profile" />
            <div style={styles.info}>
              <h2 style={styles.name}>{user ? user.name : "Loading..."}</h2>
              <p style={styles.email}>{user ? user.email : "Loading..."}</p>
            </div>
          </div>

          <div style={styles.section}>
            <div style={styles.row}>
              <div style={styles.column}>
                <p style={styles.label}>Grade</p>
                <select
                  name="grade"
                  value={formData.grade}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="">Select Grade</option>
                  {grades.map((grade) => (
                    <option key={grade} value={grade}>
                      {grade}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.column}>
                <p style={styles.label}>Location</p>
                <select
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="">Select State</option>
                  {statesOfIndia.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <p style={styles.label}>Preferred Language</p>
                <select
                  name="preferredLanguage"
                  value={formData.preferredLanguage}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="French">French</option>
                  {/* Add more languages as needed */}
                </select>
              </div>
              <div style={styles.column}>
                <p style={styles.label}>Board</p>
                <select
                  name="board"
                  value={formData.board}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="CBSE">CBSE</option>
                  <option value="ICSE">ICSE</option>
                  <option value="State Board">State Board</option>
                  {/* Add more boards as needed */}
                </select>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.columnFull}>
                <p style={styles.label}>Hobbies</p>
                <input
                  type="text"
                  name="hobbies"
                  value={formData.hobbies}
                  onChange={handleChange}
                  style={styles.input}
                  placeholder="Enter hobbies"
                />
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.columnFull}>
                <p style={styles.label}>Preparing for</p>
                <input
                  type="text"
                  name="preparingFor"
                  value={formData.preparingFor}
                  onChange={handleChange}
                  style={styles.input}
                  placeholder="Enter exam name"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            style={{
              ...styles.submitButton,
              backgroundColor: isHovered ? "#0056b3" : "#007bff"
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Save Changes
          </button>
        </form>
        <button
            style={{
              ...styles.submitButton,
              backgroundColor: isHovered ? "#D54343" : "#AD3232"
            }}
            onClick={() => logout()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >Log Out</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f7fa",
  },
  card: {
    maxWidth: "500px",
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    color: "#333",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid #eaeaea",
  },
  image: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "20px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: "20px",
    margin: 0,
    fontWeight: "bold",
  },
  email: {
    margin: "4px 0",
    color: "#555",
  },
  section: {
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "20px",
    border: "1px solid #eaeaea",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  column: {
    flex: "0 0 48%",
  },
  columnFull: {
    flex: "0 0 100%",
  },
  label: {
    fontSize: "13px",
    color: "#777",
    marginBottom: "5px",
    fontWeight: "600",
  },
  input: {
    padding: "10px",
    fontSize: "14px",
    borderRadius: "8px",
    border: "1px solid #ddd",
    width: "100%",
    marginTop: "5px",
  },
  submitButton: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    marginTop: "10px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
};

export default UserProfile;
