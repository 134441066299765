import React from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import loginImage from '../images/login-image.jpg';

function CreateAccount() {

    const responseGoogle = async (authResult)=>{
        try{
            if(authResult['code']){
                console.log(authResult);
            }
        }catch(err){
            console.error('Error : ', err);
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: responseGoogle,
        onError: responseGoogle,
        flow: 'auth-code'
    })

    return (
        <div style={styles.pageBody}>
            <div style={styles.rightSide}>
                <h1 style={styles.heading}>Create Account</h1>
                <button style={{ ...styles.authButton, ...styles.google }} onClick={googleLogin}>
                    <img src="https://img.icons8.com/color/50/google-logo.png" alt="Google icon" style={styles.icon} /> Sign Up with Google
                </button>
                <button style={{ ...styles.authButton, ...styles.apple }}>
                    <img src="https://img.icons8.com/ios-filled/50/mac-os.png" alt="Apple icon" style={styles.icon} /> Sign Up with Apple ID
                </button>
                <div style={styles.divider}>OR</div>
                <form style={styles.loginForm}>
                    <label htmlFor="email" style={styles.label}>Email*</label>
                    <input type="email" id="email" placeholder="Enter your email" required style={styles.input} />
                    <label htmlFor="password" style={styles.label}>Password*</label>
                    <input type="password" id="password" placeholder="Create a password" required style={styles.input} />
                    <p style={styles.passwordInfo}>Must be at least 8 characters.</p>
                    <button type="submit" style={styles.createAccountButton}>Create account</button>
                </form>
                <p style={styles.loginLink}>
                    Already have an account? <a href="/login">Log in</a>
                </p>
            </div>

            <div style={{ ...styles.leftSide, backgroundImage: `url(${loginImage})` }}></div>
        </div>
    );
}

const styles = {
    pageBody: {
        display: "flex",
        height: "100vh",
        fontFamily: "Arial, sans-serif",
    },
    leftSide: {
        flex: 1,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    rightSide: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
        backgroundColor: "white",
    },
    heading: {
        fontSize: "2rem",
        marginBottom: "20px",
    },
    authButton: {
        width: "50%",
        padding: "10px",
        marginBottom: "10px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        fontWeight: "bold",
    },
    google: {
        backgroundColor: "#ffffff",
        border: "1px solid #d1d1d1",
        color: "black"
    },
    apple: {
        backgroundColor: "#ffffff",
        border: "1px solid #d1d1d1",
        color: "black"
    },
    icon: {
        marginRight: "10px",
        height: "20px",
        width: "20px",
    },
    divider: {
        margin: "20px 0",
        textAlign: "center",
        fontWeight: "bold",
        color: "#aaa",
    },
    loginForm: {
        width: "100%",
        maxWidth: "400px",
    },
    label: {
        display: "block",
        fontWeight: "bold",
        marginTop: "10px",
    },
    input: {
        width: "100%",
        padding: "10px",
        marginTop: "5px",
        border: "1px solid #d1d1d1",
        borderRadius: "5px",
        fontSize: "1rem",
    },
    passwordInfo: {
        fontSize: "0.9rem",
        color: "#888",
        marginTop: "5px",
    },
    createAccountButton: {
        width: "100%",
        padding: "10px",
        marginTop: "20px",
        backgroundColor: "#000",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        fontWeight: "bold",
        cursor: "pointer",
    },
    loginLink: {
        marginTop: "20px",
        fontSize: "0.9rem"
    },
    loginLinkAnchor: {
        color: "#000",
        textDecoration: "none",
        fontWeight: "bold"
    }
};

export default CreateAccount;
