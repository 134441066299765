import React, { useState, useEffect, useCallback, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Loading from '../loading/Loading';

const Chat = ({ questions, onAnswerUpdate, onSubmit, isSubmitting, grade, subject, topic }) => {
    //const Chat = ({ questions, onAnswerUpdate, onSubmit, isSubmitting }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [userPhoto, setUserPhoto] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isMicOn, setIsMicOn] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [darkMode, setDarkMode] = useState(false); // Dark mode toggle
    const hasSentFirstMessage = useRef(false);
    const messagesEndRef = useRef(null);
    const loadingText = "evaluating . . .";
    

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const sendBotMessage = useCallback(async (text) => {
        setIsBotTyping(true);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate typing delay
        setMessages((prev) => [...prev, { sender: 'bot', text }]);
        setIsBotTyping(false);
      
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo.pic) {
            setUserPhoto(userInfo.pic);
        }

        if (questions && questions.length > 0 && !hasSentFirstMessage.current) {
            sendBotMessage(questions[0].question);
            hasSentFirstMessage.current = true;
        }
    }, [questions, sendBotMessage]);

    const handleSend = async () => {
        if (!input.trim()) return;

        setMessages((prev) => [...prev, { sender: 'user', text: input }]);

        onAnswerUpdate(currentQuestionIndex, input);

        setInput('');

        if (currentQuestionIndex < questions.length - 1) {
            const nextIndex = currentQuestionIndex + 1;
            setCurrentQuestionIndex(nextIndex);
            await sendBotMessage(questions[nextIndex].question);
        } else {
            await sendBotMessage("Thank you for completing the questions!");
            if (onSubmit) {
                setTimeout(() => {
                    onSubmit();
                }, 2000);
            }
        }
        
    };

    useEffect(() => {
        if (!listening && transcript) {
            setInput(transcript);
            resetTranscript();
            setIsListening(false);
        }
    }, [listening, transcript, resetTranscript]);

    const toggleMic = () => {
        if (isMicOn) {
            SpeechRecognition.stopListening();
            setIsMicOn(false);
            setIsListening(false);
        } else {
            SpeechRecognition.startListening({ continuous: true });
            setIsMicOn(true);
            setIsListening(true);
        }
    };

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <div style={{ ...styles.page, ...(darkMode ? styles.darkPage : {}) }}>
            {isSubmitting ? (
                <div style={styles.loadingContainer}>
                    <Loading loadingText={loadingText} />
                </div>
            ) : (
                <div style={{ ...styles.chatContainer, ...(darkMode ? styles.darkChatContainer : {}) }}>
                    {/* Header Section */}
                    <div style={styles.header}>
                    <div>
                        <h3 style={styles.headerText}>
                        {`Grade: ${grade} | Subject: ${subject} | Topic: ${topic}`}
                        </h3>
                    </div>
                    </div>
                    
                    <button
                        onClick={toggleDarkMode}
                        style={{
                            ...styles.darkModeToggle,
                            ...(darkMode ? styles.darkModeToggleActive : {})
                        }}
                    >
                        {darkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
                    </button>

                    <div style={{ ...styles.progressBarContainer, ...(darkMode ? styles.darkProgressBarContainer : {}) }}>
                        <div
                            style={{
                                ...styles.progressBar,
                                ...(darkMode ? styles.darkProgressBar : {}),
                                width: `${
                                    questions.length > 0
                                        ? (currentQuestionIndex / questions.length) * 100
                                        : 0
                                }%`,
                            }}
                        ></div>
                    </div>

                    <div style={styles.messages}>
                        {messages.map((msg, idx) => (
                            <div
                                key={idx}
                                style={
                                    msg.sender === 'user'
                                        ? styles.userContainer
                                        : styles.botContainer
                                }
                            >
                                <img
                                    src={
                                        msg.sender === 'user'
                                            ? userPhoto
                                            : 'https://img.icons8.com/ios-glyphs/30/background-remover.png'
                                    }
                                    alt={msg.sender}
                                    style={styles.avatar}
                                />
                                <div
                                    style={
                                        msg.sender === 'user'
                                            ? { ...styles.user, ...(darkMode ? styles.darkUser : {}) }
                                            : { ...styles.bot, ...(darkMode ? styles.darkBot : {}) }
                                    }
                                >
                                    <span>{msg.text}</span>
                                </div>
                            </div>
                        ))}

                        {isBotTyping && (
                            <div style={{ ...styles.typingIndicator, ...(darkMode ? styles.darkTypingIndicator : {}) }}>
                                <span>Typing...</span>
                            </div>
                        )}

                        <div ref={messagesEndRef}></div>
                    </div>

                    <div style={{ ...styles.inputContainer, ...(darkMode ? styles.darkInputContainer : {}) }}>
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder={
                                isListening ? 'Listening...' : 'Type your message here...'
                            }
                            style={{
                                ...styles.input,
                                ...(darkMode ? styles.darkInput : {}),
                            }}
                            disabled={isBotTyping || isListening}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleSend();
                                }
                            }}
                        />

                        <button
                            onClick={toggleMic}
                            style={
                                isMicOn ? styles.micButtonListening : styles.button
                            }
                        >
                            <img
                                src={
                                    isMicOn
                                        ? 'https://img.icons8.com/glyph-neue/64/stop.png'
                                        : 'https://img.icons8.com/fluency-systems-filled/50/microphone.png'
                                }
                                alt="microphone"
                                style={styles.icon}
                            />
                        </button>

                        <button onClick={handleSend} style={styles.button}>
                            <img
                                src="https://img.icons8.com/ios-filled/50/sent.png"
                                alt="send"
                                style={styles.icon}
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {

    header: {
        backgroundColor: "#808080",
        color: "#ffffff",
        padding: "15px 20px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "bold",
      },
      headerText: {
        margin: 0,
        fontSize: "18px",
      },
   
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    chatContainer: {
        fontFamily: 'Inter',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        margin: '1% auto',
        width: '100%',
        maxWidth: '850px',
        position: 'relative',
        boxSizing: 'border-box',
        backgroundColor: '#dbd7ce',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    },
    darkChatContainer: {
        backgroundColor: '#1e1e1e',
        color: '#ffffff',
        boxShadow: '0px 4px 10px rgba(255, 255, 255, 0.1)',
    },
    progressBarContainer: {
        height: '10px',
        backgroundColor: '#ffffff',
        margin: '10px 0',
        borderRadius: '15px',
    },
    darkProgressBarContainer: {
        backgroundColor: '#333333',
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#4caf50',
        borderRadius: '5px',
        transition: 'width 0.5s ease-in-out',
    },
    darkProgressBar: {
        backgroundColor: '#1db954',
    },
    messages: {
        flex: 1,
        padding: '20px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'none',
        backgroundColor: '#ffffff',
    },
    darkMessages: {
        backgroundColor: '#121212',
    },
    userContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '10px 0',
    },
    botContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px 0',
    },
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    },
    user: {
        alignSelf: 'flex-end',
        margin: '0 10px',
        padding: '10px 14px',
        maxWidth: '75%',
        wordWrap: 'break-word',
        borderRadius: '12px 12px 0 12px',
        fontSize: '14px',
        lineHeight: 1.5,
        backgroundColor: '#1a1a1a',
        color: 'white',
    },
    darkUser: {
        backgroundColor: '#555555',
    },
    bot: {
        alignSelf: 'flex-start',
        margin: '0 10px',
        padding: '10px 14px',
        maxWidth: '75%',
        wordWrap: 'break-word',
        borderRadius: '12px 12px 12px 0',
        fontSize: '14px',
        lineHeight: 1.5,
        backgroundColor: '#ffffff',
        color: '#1a1a1a',
    },
    darkBot: {
        backgroundColor: '#333333',
        color: '#ffffff',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#ffffff',
        width: '85%',
        maxWidth: '850px',
        position: 'fixed',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '15px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    darkInputContainer: {
        backgroundColor: '#1e1e1e',
        borderTop: '1px solid #555',
    },
    input: {
        flex: 1,
        padding: '12px',
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        outline: 'none',
        fontSize: '14px',
        lineHeight: 1.5,
        color: '#333333',
        backgroundColor: '#ffffff',
        marginRight: '8px',
    },
    darkInput: {
        backgroundColor: '#333333',
        color: '#ffffff',
        border: '1px solid #555',
    },
    listeningInput: {
        color: '#ff0000',
        animation: 'blinking 1s infinite',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        border: 'none',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        borderRadius: '50%',
        outline: 'none',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    micButtonListening: {
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        animation: 'pulse 1.5s infinite',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        borderRadius: '50%',
        outline: 'none',
    },
    darkModeToggle: {
        backgroundColor: '#f7f7f7',
        color: '#333',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        position: 'fixed', // Make the button float and always visible on the page
        bottom: '10px', // 10px from the bottom of the viewport
        right: '10px', // 10px from the right of the viewport
        zIndex: 1000 // Make sure it's above other items
    },
    darkModeToggleActive: {
        backgroundColor: '#333333',
        color: '#ffffff',
    },
    icon: {
        width: '20px',
        height: '20px',
    },
    typingIndicator: {
        fontStyle: 'italic',
        color: '#666666',
        alignSelf: 'flex-start',
        margin: '5px 10px',
    },
    darkTypingIndicator: {
        color: '#aaaaaa',
    },
    '@keyframes blinking': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.5 },
        '100%': { opacity: 1 },
    },
};


//const mediaQueries = `
//    @media (max-width: 768px) {
//        .chatContainer {
//            width: 95%; /* Narrower layout for smaller screens */
//        }
//       .inputContainer {
//           width: 95%; /* Match chat container */
//       }
//   }
//   @media (min-width: 1200px) {
//       .chatContainer {
//          width: 70%; /* Slightly narrower for very large screens */
//     }
//    .inputContainer {
//       width: 70%; /* Match chat container */
//  }
//};

export default Chat;
