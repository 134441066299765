import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MyFeedback = () => {
    const [feedbacks, setFeedbacks] = useState([]); // Store all feedback
    const [grade, setGrade] = useState(""); // For filtering by grade
    const [subject, setSubject] = useState(""); // For filtering by subject
    const [topic, setTopic] = useState(""); // For filtering by topic
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userId = userInfo ? userInfo._id : null;
    console.log("User ID:", userId);
    const navigate = useNavigate();

    const styles = {
        body: {
            backgroundColor: '#f8f8f5',
            width: "100%",
            height: "100vh",
            paddingTop: '5rem'
        },
        filterBody: {
            width: "800px",
            height: "65px",
            // position: "absolute",
            // top: "5rem",
            // left: "calc(50% - 400px)",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            justifySelf: 'center',
            justifyContent: 'center'
        },
        selectStyle: {
            width: "30%",
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            border: "1px solid #ccc",
        },
        feedbackCardBody: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
            marginTop: '2rem'
        },
        feedbackCard: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "30px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            width: "400px",
            display: "flex",
            flexDirection: "column",
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            position: 'relative', // Added to position the icon within the card
        },
        feedbackCardTitle: {
            fontWeight: "bold",
            fontSize: "18px",  // making the title slightly larger
            marginBottom: "5px",  // reduce spacing to tighten the grouping
        },
        feedbackCardContent: {
            display: "flex",
            flexDirection: "column",
            gap: "2px", // consistent spacing between items
        },
        feedbackCardHover: {
            transform: 'scale(1.05)',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)'
        },
        createCard: {
            backgroundColor: "#f4f4f8", // A light color to distinguish from feedback cards
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            width: "300px",
            height: "100px", // Fixed height to match the feedback cards if necessary
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#555",
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        createCardHover: {
            backgroundColor: "#dedee3" // Slightly darker on hover for visual feedback
        },
        feedbackCardContentLabel: {
            fontWeight: "bold",  // bold for labels
            //marginBottom: "2px",  // tighter spacing between label and value
            //fontWeight: "normal",
            fontSize: "16px",  // Slightly larger font size for labels
            lineHeight: "24px",  // Adjusted for better spacing
            marginBottom: "12px"
        },
        feedbackCardContentValue: {
            //fontWeight: "normal",  // normal weight for values
            //marginBottom: "10px",  // space before the next item
            fontWeight: "normal",
            fontSize: "16px",  // Slightly larger font size for content
            lineHeight: "24px",  // Adjusted for better spacing
            marginBottom: "12px" 
        },
        confidenceIcon: {
            position: 'absolute',
            top: '10px', // Adjust as needed
            right: '10px', // Adjust as needed
            fontSize: '24px' // Size of the icon
        },
    };

    useEffect(() => {
        // Fetch feedback data when the component mounts
        const fetchFeedbacks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_ENDPOINT}/api/all-feedback?userId=${userId}`);
                setFeedbacks(response.data.feedback); // Assuming `response.data.feedback` contains the array
            } catch (error) {
                console.error("Error fetching feedbacks:", error);
            }
        };

        fetchFeedbacks();
    }, [userId]);

    // Filter the feedbacks based on grade, subject, and topic
    const filteredFeedbacks = feedbacks.filter((feedback) => {
        return (
            (!grade || feedback.grade === grade) &&
            (!subject || feedback.subjectName === subject) &&
            (!topic || feedback.chapterName === topic)
        );
    });

    const handleCardClick = (feedbackId) => {
        navigate(`/feedback/${feedbackId}`);
    };
    const [hoveredFeedbackId, setHoveredFeedbackId] = useState(null);
    const [isCreateCardHovered, setCreateCardHovered] = useState(false);

    function getConfidenceDetails(score) {
        let details = { color: "#F44336", icon: "🔴" }; // Default to low confidence
        if (score > 60) {
            details = { color: "#4CAF50", icon: "🟢" }; // High confidence
        } else if (score > 30) {
            details = { color: "#FFEB3B", icon: "🟡" }; // Medium confidence
        }
        return details;
    }

    return (
        <div style={styles.body}>
            {/* Filter */}
            <div style={styles.filterBody}>
                <select
                    style={styles.selectStyle}
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                >
                    <option value="">Grade</option>
                    {[...new Set(feedbacks.map((fb) => fb.Grade))].map((grade) => (
                        <option key={grade} value={grade}>
                            {grade}
                        </option>
                    ))}
                </select>
                <select
                    style={styles.selectStyle}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                >
                    <option value="">Subject</option>
                    {[...new Set(feedbacks.map((fb) => fb.subjectName))].map((subject) => (
                        <option key={subject} value={subject}>
                            {subject}
                        </option>
                    ))}
                </select>
                <select
                    style={styles.selectStyle}
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                >
                    <option value="">Topic</option>
                    {[...new Set(feedbacks.map((fb) => fb.chapterName))].map((topic) => (
                        <option key={topic} value={topic}>
                            {topic}
                        </option>
                    ))}
                </select>
            </div>

            {/* Feedback Cards */}
                
            <div style={styles.feedbackCardBody}>
                {filteredFeedbacks.map((feedback) => (
                    <div
                        key={feedback._id}
                        style={{
                            ...styles.feedbackCard,
                            ...(hoveredFeedbackId === feedback._id && styles.feedbackCardHover)
                        }}
                        onClick={() => handleCardClick(feedback._id)}
                        onMouseEnter={() => setHoveredFeedbackId(feedback._id)}
                        onMouseLeave={() => setHoveredFeedbackId(null)}
                    >
                        <div style={styles.feedbackCardTitle}>Grade: {feedback.grade}</div>
                        <div>
                            <span style={styles.feedbackCardContentLabel}><span role="img" aria-label="Subject">📘</span> Subject:</span>
                            <span style={styles.feedbackCardContentValue}>{feedback.subjectName}</span>
                        </div>
                        <div>
                            <span style={styles.feedbackCardContentLabel}><span role="img" aria-label="Chapter">📄</span>Chapter:</span>
                            <span style={styles.feedbackCardContentValue}>{feedback.chapterName}</span>
                        </div>
                        <div>
                            <span style={styles.feedbackCardContentLabel}><span style={styles.confidenceIcon} role="img" aria-label={`${feedback.confidenceScore || "N/A"} Confidence`}>
                                    {getConfidenceDetails(feedback.confidenceScore).icon}
                                </span>Confidence Score:</span>
                            <span style={styles.feedbackCardContentValue}>
                                <span style={{ 
                                        backgroundColor: getConfidenceDetails(feedback.confidenceScore).color, 
                                        color: "#fff", 
                                        padding: "3px 6px", 
                                        borderRadius: "5px" 
                                    }}>
                                    {feedback.confidenceScore || "N/A"}
                                </span>
                            </span>
                        </div>
                    </div>
                ))}
                {/* New "Create New Notes" card */}
                <div
                    style={{
                        ...styles.createCard,
                        ...(isCreateCardHovered && styles.createCardHover)
                    }}
                    onClick={() => navigate('/choose-topic')}
                    onMouseEnter={() => setCreateCardHovered(true)}
                    onMouseLeave={() => setCreateCardHovered(false)}
                >
                    Create New Notes
                </div>
            </div>
                         
                        

            
        </div>
    );
};

export default MyFeedback;
