import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CardBGimg from '../images/cardBGimg.jpg';



const ShowFeedback = () => {
    const { id } = useParams(); // Get feedback ID from route params
    const [feedbackData, setFeedbackData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTopic, setActiveTopic] = useState(null); // Moved out of conditional logic
    const [isExpandedPersonalizedNotes, setIsExpandedPersonalizedNotes] = useState(false);

    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_ENDPOINT}/api/get-feedback/${id}`);
                const data = response.data;

                setFeedbackData(data); // Assuming response contains the feedback object

                // Set the default active topic if topics exist
                if (data.feedbackContent && data.feedbackContent.topics.length > 0) {
                    setActiveTopic(data.feedbackContent.topics[0].name);
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching feedback:", error);
                setLoading(false);
            }
        };

        fetchFeedback();
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!feedbackData || !feedbackData.feedbackContent) {
        return <p>Feedback not found.</p>;
    }

    // Safely extract data from feedbackData
    const {
        personalizedNotes = {
            introduction: "",
            keyConcepts: [],
            importantFormulasOrEquations: [],
            studyTips: [],
        },
        subject = "N/A",
        chapterName = "N/A",
        chapterNumber = "N/A",
        timestamp = "",
        studentEvaluation = { confidenceScore: 0 },
        topicCloud = { needsRevision: [], suggestedResources: [] },
        topics = [],
    } = feedbackData.feedbackContent;

    // Find active data
    const activeData = topics.find((topic) => topic.name === activeTopic) || { feedback: [], score: 0 };

    const handleToggleExpand = () => {
        setIsExpandedPersonalizedNotes(!isExpandedPersonalizedNotes);
    };

    const handleTopicClick = (topicName) => {
        setActiveTopic(topicName);
    };
  

  const styles = {

    
    container: {
      fontFamily: 'Inter',
      padding: '20px',
      color: '#333',
      marginTop: '3%',
      backgroundColor: '#E8EAF5',
      minHeight: '89vh'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      justifySelf: 'center',
      alignItems: 'center',
      borderBottom: '1px solid #ddd',
      padding: '20px 15px',
      width: '73.5%',
      backgroundColor: '#FCFCFE',
      borderRadius: '15px',
    },
    title: {
      margin: 0,
      fontSize: '20px',
      fontWeight: 600
    },
    button: {
      padding: '8px 16px',
      backgroundColor: '#1a1a1a',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      height: '40px'
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    content: {
      display: 'flex',
      marginTop: '20px',
      gap: '20px',
      width: "75%",
      justifySelf: "center"
    },
    headingIcon: {
      verticalAlign: 'middle',
      marginRight: '5px',
      width: '24px',
      height: '24px',
    },
    card: {
      flex: 1,
      border: '1px solid #ddd',
      padding: '20px',
      borderRadius: '15px',
      backgroundColor: 'white',
      width: '72.75%',
      justifySelf: 'center',
      marginTop: '15px'
    },
    link: {
      textDecoration: 'none',
      color: '#2961F6',
      cursor: 'pointer',
      marginTop: '10px',
      fontSize: '14px',
      fontWeight: '400'
    },
    notesContent: {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: isExpandedPersonalizedNotes ? 'none' : 4, // Show full content if expanded, else limit to 4 lines
    },
    topicCard: {
        flex: "1 1 60%",
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '15px',
        backgroundColor: '#ffffff'
        },
    topic: {
      display: 'inline-block',
      padding: '5px 10px',
      margin: '5px',
      borderRadius: '5px',
      border: '1px solid #00000014',
      fontSize: '14px',
      fontWeight: '400',
      color: '#5D6279'
    },
    confidenceScoreCard: {
      flex: "1 1 40%",
      display: 'flex',  // Set display to flex to align children side by side
      justifyContent: 'space-between', // Space between score and feedback
      alignItems: 'center', // Align items vertically in the center
      border: '1px solid #ddd',
      padding: '20px',
      borderRadius: '15px',
      backgroundImage: `url(${CardBGimg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: 'white',
      alignContent: 'space-around'
    },
    confidenceScore: {
      flex: 2,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    progressBarContainer: {
      height: '10px',
      width: '90%', // Ensure this is set so the bar can expand based on its parent
      backgroundColor: '#ddd', // This should contrast with the progressBar's color
      borderRadius: '15px',
      overflow: 'hidden', // Make sure overflow isn't hiding the progress bar
      margin: '10px 0',
    },
    
    progressBar: {
      height: '100%',
      backgroundColor: '#007bff', // This should be clearly visible against #ddd
      borderRadius: '5px',
      width: '0%', // Make sure this starts at 0% and increases with the score
      transition: 'width 0.3s ease-in-out', 
    },
    overallFeedbackInCard: {
      flex: 1.5, // Allow the feedback to take the other half
      padding: '10px',
      backgroundColor: '#000000',  // Ensure it matches the card background
      borderRadius: '5px',
      fontSize: '14px',
      fontStyle: 'italic',
      color: '#FFFFFF', // Ensure text is readable
      display: 'flex',
      justifyContent: 'center', // Center feedback text
      alignItems: 'center', // Vertically align text
      borderLeft: '5px solid #FFD700'
    },
    overallFeedbackTextInCard: {
      fontSize: '14px', // Smaller font size
      color: '#FFFFFF', // White text for readability on black background
      fontStyle: 'italic' // Optional: italicize to denote a quote
    },
    notesList: { listStyleType: 'none', padding: 0 },
    noteItem: { margin: '10px 0' },
    topicDetails: {
      display: 'flex',
      marginTop: '20px',
      gap: '20px',
      width: '75%',
      justifySelf: 'center',
    },
    nav: {
      flex: 1,
      borderRadius: '15px',
      backgroundColor: 'white',
      padding: '20px'
    },
    navItem: {
      padding: '10px',
      cursor: 'pointer',
      borderBottom: '1px solid #ddd',
      backgroundColor: 'white'
    },
    navActive: {
      backgroundColor: '#0056b3',
      color: 'white',
      borderRadius: '5px',
    },
    feedbackCard: {
      flex: 2,
      border: '1px solid #ddd',
      padding: '20px',
      borderRadius: '15px',
      backgroundColor: 'white'
    },
    feedbackHeader: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
    },
    feedbackContent: {
      backgroundColor: '#f8f8f8',
      padding: '15px',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    
  };

  const BookIcon = () => (
    <svg style={{ fill: "#4A90E2", height: "24px", width: "24px" }} viewBox="0 0 24 24">
      <path d="M18 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H6V4h12v16z"/>
    </svg>
  );
  const KeyIcon = () => (
    <svg style={{ fill: "#4A90E2", height: "24px", width: "24px" }} viewBox="0 0 24 24">
      <path d="M7 14c1.66 0 3-1.34 3-3S8.66 8 7 8 4 9.34 4 11s1.34 3 3 3zm12 8h-8v-2h8v2zm0-4h-8v-2h8v2zm-3.5-14c3.58 0 6.5 2.92 6.5 6.5S19.08 17 15.5 17H12v5H10v-5H7v-2h3v-3c0-1.1.9-2 2-2s2 .9 2 2v3h3.5c1.93 0 3.5-1.57 3.5-3.5S18.43 9 16.5 9H13v2h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H12v-4.5C12 6.92 13.92 5 16 5z"/>
    </svg>
  );
  const FunctionsIcon = () => (
    <svg style={{ fill: "#4A90E2", height: "24px", width: "24px" }} viewBox="0 0 24 24">
      <path d="M11 24h2v-2h-2v2zm0-22h2V0h-2v2zm4.83 3.17l1.41-1.42-1.41-1.41-1.42 1.41 1.42 1.42zM6.34 6.34L4.93 4.93 3.51 6.34l1.41 1.42 1.42-1.42zM1 11v2h2v-2H1zm22 0v2h2v-2h-2zm-7.66 5.66l1.41 1.42 1.42-1.42-1.42-1.41-1.41 1.41zM4.93 19.07l1.41 1.42 1.42-1.42-1.42-1.41-1.41 1.41zM11 22h2v-2h-2v2zM12 7a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
    </svg>
  );
  const TipsAndUpdatesIcon = () => (
    <svg style={{ fill: "#4A90E2", height: "24px", width: "24px" }} viewBox="0 0 24 24">
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V3c0-.83-.67-1.5-1.5-1.5S10.5 2.17 10.5 3v1.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-6 3c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm1-8H11V5h2v4z"/>
    </svg>
  );
  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
       <div>
        <h2 style={styles.title}>{subject} | {chapterName} #{chapterNumber}</h2>
        <p style={{margin: "0px", fontSize: "15px", fontWeight: "400", color: "#808080"}} >{timestamp}</p>
        </div>
        <button
          style={styles.button}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#2a2a2a')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#1a1a1a')}
        >
          Download Report
        </button>
        
      </div>

      {/* Main Content */}
      <div style={styles.content}>
        {/* Topic Cloud */}
        <div style={styles.topicCard}>
          <span style={{fontSize: '16px', fontWeight: '500', color: 'black'}} >Topic Cloud (which needs revision)</span>
          <div style={{ display: 'flex', flexWrap: 'wrap', }}>
              {topicCloud.needsRevision.slice(0, 6).map((topic, index) => (
                  <span key={index} style={styles.topic}>{topic}</span>
              ))}
              {topicCloud.needsRevision.length > 6 && (
                  <span style={{ color: '#2961F6', fontSize: '14px', fontWeight: '600', alignContent: 'center' }}>
                      +{topicCloud.needsRevision.length - 6}
                  </span>
              )}
          </div>
          <div style={{height: '1px', width: '95%', backgroundColor: '#D9DCE9', margin: '1%', justifySelf: 'center'}}></div>
          <span style={{fontSize: '16px', fontWeight: '500', color: 'black'}} >Resources for suggested topics</span>
          <div style={{ display: 'flex', flexWrap: 'wrap', }}>
              {topicCloud.suggestedResources.slice(0, 6).map((resource, index) => (
                  <span
                      key={index}
                      style={{
                          ...styles.topic,
                          whiteSpace: 'nowrap', // Prevent text from wrapping
                          //overflow: 'hidden', // Hide overflowing text
                          textOverflow: 'ellipsis', // Add ellipsis to overflowed text
                          //maxWidth: '200px', // Limit the width of each resource
                      }}
                  >
                      {resource}
                  </span>
              ))}
              {topicCloud.suggestedResources.length > 6 && (
                  <span style={{ color: '#2961F6', fontSize: '14px', fontWeight: '600', alignContent: 'center' }}>
                      +{topicCloud.suggestedResources.length - 6}
                  </span>
              )}
        </div>
</div>



<div style={styles.confidenceScoreCard}>
  <div style={styles.confidenceScore}>
    <span style={{fontSize: '24px', fontWeight: '600'}}>Confidence Score</span>
    <span style={{fontSize: '80px', fontWeight: 'bold'}}>{studentEvaluation.confidenceScore}</span><span>%</span>
    <div style={styles.progressBarContainer}>
      <div
        style={{
          ...styles.progressBar,
          width: `${studentEvaluation.confidenceScore}%`,
          backgroundColor: studentEvaluation.confidenceScore >= 85 ? '#4caf50' : studentEvaluation.confidenceScore >= 40 ? '#ffc107' : '#f44336',
        }}
      ></div>
    </div>
    <span style={{fontSize: '16px', fontWeight: '500'}}>comeback after 5 days</span>
  </div>
  <div style={styles.overallFeedbackInCard}>
    <p style={styles.overallFeedbackTextInCard}>
      “{studentEvaluation.overallFeedback}”
    </p>
  </div>
</div>
      </div>

      {/* Personalized Notes */}
      <div style={styles.card}>
            <h2>Personalized Notes</h2>
              <div style={styles.notesContent}>
                    {/* Introduction */}
                    <div style={styles.notesSection}>
                      <h3><BookIcon /> Introduction</h3>
                        <p>{personalizedNotes.introduction}</p>
                    </div>

                    {/* Key Concepts */}
                    <div style={styles.notesSection}>
                      <h3><KeyIcon /> Key Concepts</h3>

                        {personalizedNotes.keyConcepts.map((concept, index) => (
                            <div key={index}>
                                <p style={{...styles.conceptTitle, fontWeight:'bold', color:'#222222'}}>{concept.concept}</p>
                                <p>{concept.details}</p>
                            </div>
                        ))}
                    </div>

                    {/* Important Formulas */}
                    <div style={styles.notesSection}>
                    <h3><FunctionsIcon /> Important Formulas or Equations</h3>
                    {personalizedNotes.importantFormulasOrEquations.map((formula, index) => (
                            <div key={index}>
                                <p style={styles.conceptTitle}>{formula.formula}</p>
                                <p>{formula.usage}</p>
                            </div>
                        ))}
                    </div>

                    {/* Study Tips */}
                    <div style={styles.notesSection}>
                    <h3><TipsAndUpdatesIcon /> Study Tips</h3>
                    <ul>
                            {personalizedNotes.studyTips.map((tip, index) => (
                                <li key={index}>{tip}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* Toggle "See more/See less" */}
                <div style={styles.link} onClick={handleToggleExpand}>
                    {isExpandedPersonalizedNotes ? 'see less' : 'see more'}
                </div>
      </div>

      {/*Feedback*/}
      <div style={{width: '75%', justifySelf: 'center', marginTop: '50px'}}>
        <h2>Feedback</h2>
      </div>
      

      {/* Topic Details */}
      <div style={styles.topicDetails}>
                  {/* Topics Navigation */}
                  <div style={styles.nav}>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              {topics.map((topic) => (
                <li key={topic.name} style={{
                    ...styles.navItem,
                    ...(activeTopic === topic.name ? styles.navActive : {}),
                    cursor: 'pointer'
                }} onClick={() => handleTopicClick(topic.name)}>
                  {topic.name}
                </li>
              ))}
            </ul>
          </div>

        {/* Topic Feedback */}
        <div style={styles.feedbackCard}>
        <div style={styles.feedbackHeader}>
            <h3 style={{ marginBottom: '10px', color: '#333', fontSize: '24px', fontWeight: 'bold' }}>
              {activeTopic}
            </h3>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <div style={styles.progressBarContainer}>
                <div style={{
                    ...styles.progressBar,
                    width: `${activeData.score}%`,  // Dynamic width based on score
                    backgroundColor: activeData.score >= 85 ? '#4caf50' :
                                    activeData.score >= 40 ? '#ffc107' : '#f44336',  // Color based on score value
                }}></div>
              </div>
              <span style={{ fontSize: '16px', fontWeight: '500' }}>{activeData.score}%</span>
            </div>
          </div>
                        <div style={styles.feedbackContent}>
                {activeData.feedback.map((feedback, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <h4 style={{ fontSize: '18px', fontWeight: '600', color: '#333' }}>{feedback.title}</h4>
                    <ul style={{ paddingLeft: '20px' }}>
                      {feedback.details.map((detail, idx) => (
                        <li key={idx} style={{ fontSize: '14px', color: '#666' }}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFeedback;
