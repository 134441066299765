import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import educationalImage from '../images/Login-page.jpeg'; // Ensure the image path is correct

function Login({ setUser }) {
    const navigate = useNavigate();
    const sendGoogleCode = async (code) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BE_ENDPOINT}/api/users/googlelogin`, {
            code,
            });
            console.log("Google login response:", response.data);
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            setUser(response.data); // Update user state
            navigate('/my-feedback');
        } catch (error) {
            console.error("Google login error:", error.response);
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => sendGoogleCode(tokenResponse.code),
        onError: (error) => console.error("Google login failed:", error),
        flow: 'auth-code',
    });

    return (
        <div style={styles.pageBody}>
            <div style={{ ...styles.leftSide, backgroundImage: `url(${educationalImage})` }}>
                <div style={styles.infoPanel}>
                    <h2 style={styles.animatedText}>Empowering Students in Every Corner of India</h2>
                    <ul style={styles.list}>
                        <li style={styles.animatedText}>Custom Learning Paths: Study smarter with tailored guidance.</li>
                        <li style={styles.animatedText}>Multilingual Access: Learn in your preferred language</li>
                        <li style={styles.animatedText}>Track Your Progress: Easily monitor and improve your performance.</li>
                    </ul>
                </div>
            </div>
            <div style={styles.rightSide}>
                <h1 style={styles.heading}>Welcome Back!</h1>
                <div style={styles.buttonGroup}>
                    <button style={{ ...styles.authButton, ...styles.google }} onClick={googleLogin}>
                        <img src="https://img.icons8.com/color/50/google-logo.png" alt="Google icon" style={styles.icon} />
                        Sign in with Google
                    </button>
                    <button style={{ ...styles.authButton, ...styles.apple }}>
                        <img src="https://img.icons8.com/ios-filled/50/mac-os.png" alt="Apple icon" style={styles.icon} />
                        Sign in with Apple ID
                    </button>
                </div>
                <div style={styles.divider}>OR</div>
                <form style={styles.loginForm}>
                    <label htmlFor="email" style={styles.label}>Email*</label>
                    <input type="email" id="email" placeholder="Enter your email" required style={styles.input} />
                    <label htmlFor="password" style={styles.label}>Password*</label>
                    <input type="password" id="password" placeholder="Create a password" required style={styles.input} />
                    <button type="submit" style={styles.createAccountButton}>Create account</button>
                </form>
            </div>

            <style>
        {`
                        @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translate3d(0, 20px, 0);
                    }

                    to {
                        opacity: 1;
                        transform: none;
                    }
                }
        `}
            </style>
        </div>
    );
}

const styles = {
    pageBody: {
        display: "flex",
        height: "100vh",
        fontFamily: "Arial, sans-serif",
        backgroundColor: '#f8f8f5', // Adjusted to match the image's light background
    },
    leftSide: {
        flex: 1,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: '10px', // Rounded borders
        overflow: 'hidden', // Ensures the image doesn't bleed outside the border
        border: '5px solid white', // White border for aesthetics
    },
    rightSide: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
    },
    heading: {
        fontSize: "2rem",
        marginBottom: "20px",
    },
    authButton: {
        width: "100%",
        padding: "10px",
        marginBottom: "10px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        fontWeight: "bold",
    },
    google: {
        backgroundColor: "#ffffff",
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)', // Slight shadow for depth
        color: "black"
    },
    apple: {
        backgroundColor: "#ffffff",
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)', // Consistent styling with Google button
        color: "black"
    },
    icon: {
        marginRight: "10px",
        height: "20px",
        width: "20px",
    },
    divider: {
        margin: "20px 0",
        textAlign: "center",
        fontWeight: "bold",
        color: "#aaa",
    },
    loginForm: {
        width: "100%", // Ensure this is full width of the form area
        maxWidth: "300px", // Slightly wider to balance form aesthetics
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    label: {
        display: "block",
        fontWeight: "bold",
        marginTop: "10px",
        width: "100%" // Ensure labels span the full width of input
    },
    input: {
        width: "100%", // Full width of the form
        padding: "10px",
        marginTop: "5px",
        border: "1px solid #d1d1d1",
        borderRadius: "5px",
        fontSize: "1rem",
    },
    createAccountButton: {
        width: "100%",
        padding: "10px",
        marginTop: "20px",
        backgroundColor: "#000",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        fontWeight: "bold",
        cursor: "pointer",
    },
    infoPanel: {
       // color: 'white',
        padding: '20px',
        //background: 'rgba(0, 0, 0, 0.8)',  // Semi-transparent overlay
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', /* Subtle shadow for depth */

        backgroundColor: '#f2f4f8', /* Light grey background for the panel */
        borderRadius: '8px', /* Rounded corners for the panel */
        width: '45%', /* Set width of the panel */
        boxSizing: 'border-box' /* Includes padding and border in the element's total width and height */

    },
    animatedText: {
        animation: 'fadeInUp 1s ease-out',
        textAlign: 'center', 
        //textTransform: 'uppercase',
    },
    list: {
       listStyleType: 'none', /* No bullet points */
       paddingLeft: '0', /* Align text to the left */
       marginTop: '10px' /* Space above the list */
    }
};


export default Login;
